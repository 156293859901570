import { useToken } from '@/shared/lib';

const ROLES = {
  ADMIN: 'admin',
};

const LABELS = {
  ADMIN: 'Модератор',
  USER: 'Пользователь',
};

const useRoles = () => {
  const decodedToken = useToken();
  const isAdmin = () => (
    decodedToken.groups.includes(ROLES.ADMIN)
  );
  const getRoleLabel = () => (
    isAdmin() ? LABELS.ADMIN : LABELS.USER
  );
  return {
    isAdmin,
    getRoleLabel,
  };
};

export default useRoles;
