<template>
  <section>
    <VCard>
      <VLayout>
        <VAppBar
          v-if="mobile"
          color="primary"
          prominent
        >
          <VAppBarNavIcon
            variant="text"
            @click.stop="mobileDrawerIsOpen = !mobileDrawerIsOpen"
          />
          <VToolbarTitle>MPPM Wiki</VToolbarTitle>
        </VAppBar>
        <Sidebar
          v-model:mobile-drawer="mobileDrawerIsOpen"
          @openChats="channgeChatWindowStatus('opened')"
        />
        <VMain>
          <section class="page">
            <RouterView />
            <ChatList
              v-if="chatWindowStatus !== 'closed'"
              type="chat"
              title="Мои чаты"
              loading-text="Загрузка чатов..."
              :is-opened="chatWindowStatus"
              @changeWindowStatus="channgeChatWindowStatus"
            ></ChatList>
          </section>
        </VMain>
      </VLayout>
    </VCard>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import { useDisplay } from 'vuetify';
import { Sidebar } from '@/widgets/layout';
import { ChatList } from '@/widgets/chat';
import { useChats } from '@/widgets/chat/model';
const mobileDrawerIsOpen = ref(false);
const { mobile } = useDisplay();
const { chatWindowStatus, channgeChatWindowStatus } = useChats();
</script>

<style lang="scss">
$small-devices-size: 767px;
.page {
  padding: 32px;
}
@media (max-width: $small-devices-size) {
  .page {
    padding: 24px 6vw;
  }
}
</style>
