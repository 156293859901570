import { httpClient, setTokenInRequest } from '@/app/api/_httpClient';
import { Bugfender } from '@bugfender/sdk';

const BASE_URL = '/profile/';

const get = async () => {
  setTokenInRequest();
  return await httpClient.get(BASE_URL);
};

const save = async (form, id) => {
  setTokenInRequest();
  form = JSON.parse(JSON.stringify(form));
  const options = {
    fields: form[id],
    id,
  };
  const url = `${BASE_URL}/update/`;
  Bugfender.log('/api/v1/profile/update call');
  Bugfender.log(options);
  return await httpClient.post(url, options);
};

const profile = {
  get,
  save
};

export default profile;
