import { ref, inject, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Bugfender } from '@bugfender/sdk';

const ALLOWED_LISTS_TYPES = ['articles', 'edits', 'single_edits', 'chat', 'admin_chats'];

const useListFetcher = (listType = 'articles') => {
  if (!ALLOWED_LISTS_TYPES.includes(listType)) {
    throw new Error('Такого типа списка не существует');
  }
  let pageInfo = ref({});
  const itemsPerPage = ref(20);
  const route = useRoute();
  const router = useRouter();
  const loading = ref(true);
  const api = inject('api');
  const search = ref('');
  const searchFilters = ref('');
  const mppmId = ref('');
  const statusArr = [
    { id: 3, name: 'Только что создана, ждет реакции модератора', },
    { id: 7, name: 'Отклонена модератором', },
    { id: 8, name: 'Принята модератором, ждет процессинга', },
    { id: 9, name: 'Обработана и успешно применена', },
    { id: 13, name: 'Обработана и не смогла быть применена', },
  ];
  const status = ref(null);
  const users = ref([]);
  const user = ref(null);
  const selectedStartDate = ref(null);
  const formattedStartDate = computed(() => {
    return !!selectedStartDate.value ? formatDate(selectedStartDate.value) : '';
  });
  watch(formattedStartDate, () => updateSearch());
  const selectedEndDate = ref(null);
  const formattedEndDate = computed(() => {
    return !!selectedEndDate.value ? formatDate(selectedEndDate.value) : '';
  });
  watch(formattedEndDate, () => updateSearch());
  const searchData = {};
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const localDate = `${year}-${month}-${day}`;
    return localDate;
  };
  const updateSearch = () => {
    const isFormNotEmpty = !!mppmId.value || !!status.value || !!user.value || !!formattedStartDate.value || !!formattedEndDate.value;
    if (isFormNotEmpty) {
      if (!!mppmId.value) searchData.mppm_id = mppmId.value;
      if (!!status.value) searchData.status = statusArr.find(st => st.name === status.value).id;
      if (!!user.value) searchData.user = users.value.find(usr => usr.text === user.value).id;
      if (!!formattedStartDate.value) searchData.start_date = formattedStartDate.value;
      if (!!formattedEndDate.value) searchData.end_date = formattedEndDate.value;
      searchFilters.value = String(Date.now());
    } else if (!!searchFilters.value) {
      searchFilters.value = '';
    }
  };
  const getUsers = async() => {
    await api.users.getAll()
      .then((response) => {
        users.value = response.data.data.data;
      });
  };
  const loadItems = async(params) => {
    loading.value = true;
    if (route.name === 'adminsingle-id') {
      if (!!searchFilters.value) {
        await api[listType].searchInGroup({...params, search: searchData}, route.params.id).then((response) => {
          Bugfender.log('/api/v1/actions response');
          Bugfender.log(response.data);
          pageInfo.value = {
            ...response.data.data,
          };
          loading.value = false;
        });
      } else {
        await api[listType].getGroup(params, route.params.id).then((response) => {
          pageInfo.value = {
            ...response.data.data,
          };
          loading.value = false;
          if (pageInfo.value.data.items.length == 0) {
            router.push('/admin');
          }
        });
      }
    } else if (!!searchFilters.value && listType !== 'articles') {
      await api[listType].searchInAll({...params, search: searchData}).then((response) => {
        const uri = listType === 'edits' ? '/api/v1/actions/bulk' : '/api/v1/actions';
        Bugfender.log(`${uri} response`);
        Bugfender.log(response.data);
        pageInfo.value = {
          ...response.data.data,
        };
        loading.value = false;
      });
    } else {
      if (!searchFilters.value) {
        await api[listType].getAll(params).then((response) => {
          pageInfo.value = {
            ...response.data.data,
          };
          loading.value = false;
        });
      } else if (searchFilters.value.length > 3) {
        await api[listType].search(params).then((response) => {
          Bugfender.log('/api/v1/list response');
          Bugfender.log(response.data);
          pageInfo.value = {
            ...response.data.data,
          };
          loading.value = false;
        });   
      }
    }
  };
  const searchChats = async(filters) => {
    loading.value = true;
    await api.admin_chats.search(filters)
      .then((response) => {
        Bugfender.log('/api/v1/chat/list response');
        Bugfender.log(response.data);
        pageInfo.value = {
        ...response.data.data,
        };
        loading.value = false;
      });
  };
  const searchItems = async(params) => {
    loading.value = true;
    await api[listType].search(params, search.value)
      .then((response) => {
        pageInfo.value = {
        ...response.data.data,
        };
        loading.value = false;
      });
  };
  return {
    pageInfo,
    itemsPerPage,
    loadItems,
    loading,
    search,
    searchFilters,
    mppmId,
    statusArr,
    status,
    users,
    user,
    selectedStartDate,
    formattedStartDate,
    selectedEndDate,
    formattedEndDate,
    searchItems,
    searchChats,
    updateSearch,
    getUsers,
  };
};

export default useListFetcher;
