import 'vuetify/styles';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, md } from 'vuetify/iconsets/md';
import { ru } from 'vuetify/locale';

import { customSVGs } from '../assets/customSVGs';

export default createVuetify({
  components,
  directives,
  locale: {
    locale: 'ru',
    fallback: 'ru',
    messages: {
      ru,
    },
  },
  icons: {
    defaultSet: 'md',
    aliases,
    sets: {
      md,
      custom: customSVGs,
    },
  },
});
