import { httpClient, setTokenInRequest } from '@/app/api/_httpClient';

const BASE_URL = '/chat/';

const getAll = async (params = {}) => {
  setTokenInRequest();
  const options = {
    params: {
      limit: params.itemsPerPage,
      page: parseInt(params.page) - 1,
    },
  };
  return await httpClient.get(BASE_URL, options);
};

const chat = {
  getAll,
};

export default chat;