<template>
  <VNavigationDrawer
    :model-value="modelValue"
    :expand-on-hover="!mobile"
    :rail="!mobile"
    @mouseenter="showLogo = true"
    @mouseleave="showLogo = false"
    @update:model-value="(value) => emits('update:mobile-drawer', value)"
  >
    <VList>
      <div v-if="showLogo || mobile" class="d-flex justify-center">
        <BaseLogo></BaseLogo>
      </div>
      <div v-else class="d-flex justify-center">
        <MiniLogo />
      </div>
      <VListItem
        base-color="primary"
        prepend-icon="account_circle"
        :title="decodedToken.email"
        :subtitle="getRoleLabel()"
      />
    </VList>
    <VDivider />
    <VList
      density="compact"
      nav
    >
      <VListItem
        base-color="primary"
        prepend-icon="person"
        title="Профиль"
        @click="$router.push('/profile')"
        link
      />
      <VListItem
        base-color="primary"
        prepend-icon="custom:chatMenuIcon"
        title="Чаты"
        @click="emits('open-chats')"
      />
    </VList>
    <VDivider />
    <VList
      density="compact"
      nav
    >
      <VListItem
        base-color="primary"
        prepend-icon="article"
        title="Статьи"
        @click="$router.push('/articles')"
        link
      />
      <VListItem
        v-if="isAdmin()"
        base-color="primary"
        prepend-icon="admin_panel_settings"
        title="Панель модератора"
        @click="$router.push('/admin')"
        link
      />
      <VListItem
        v-if="isAdmin()"
        base-color="primary"
        prepend-icon="admin_panel_settings"
        title="История правок"
        @click="$router.push('/adminsingle')"
        link
      />
      <VListItem
        v-if="isAdmin()"
        base-color="primary"
        prepend-icon="feedback"
        title="Чаты на проверку"
        @click="$router.push('/chats')"
        link
      />
      <VListItem
        base-color="primary"
        prepend-icon="logout"
        title="Выйти"
        @click="logout"
        link
      />
    </VList>
  </VNavigationDrawer>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useDisplay } from 'vuetify';
import BaseLogo from '@/shared/ui/BaseLogo.vue';
import MiniLogo from '@/shared/ui/MiniLogo.vue';
import { useToken } from '@/shared/lib';
import { useLogout } from '@/widgets/layout/model';
import { useRoles } from '@/shared/model';
const props = defineProps({
  mobileDrawer: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['update:mobile-drawer', 'open-chats']);
const { mobile } = useDisplay();
const modelValue = computed(() => {
  if (!mobile.value) {
    return true;
  }
  return props.mobileDrawer;
});
const showLogo = ref(false);
const { logout } = useLogout();
const decodedToken = useToken();
const { isAdmin, getRoleLabel } = useRoles();
</script>
