<template>
  <div class="chat rounded-lg" :class="{'chat-opened': chat.isOpened}">
    <VToolbar density="compact" class="rounded-t-lg">
      <VAvatar class="ml-2" size="36">
        <VImg :src="chat.avatar"></VImg>
      </VAvatar>
      <div class="ml-2 font-weight-medium line-h20">{{ chat.title }}</div>
      <VSpacer></VSpacer>
      <VBtn size="x-small" @click="emits('minimize', chat.id)">
        <VIcon icon="open_in_full" size="16"></VIcon>
      </VBtn>
      <VBtn size="x-small" @click="emits('close', chat.id)">
        <VIcon icon="close" size="16"></VIcon>
      </VBtn>
    </VToolbar>
    <iframe v-if="chat.isOpened" :src="chat.url" width="100%" height="500" class="rounded-b-lg"></iframe>
  </div>
</template>

<script setup>
const props = defineProps({
  chat: {
    type: Object,
    default () {
      return {};
    }
  }
});
const emits = defineEmits(['close', 'minimize']);
</script>

<style lang="scss" scoped>
.chat {
  width: 360px;
  max-height: 500px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
}
.chat-opened {
  height: 500px;
}
iframe {
  border: none;
}
</style>
