<template>
  <svg width="35" height="67" viewBox="0 0 372 761" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M372 3.12783C372 3.12783 326.874 117.532 224.25 177.01C165.418 211.107 100.461 219.75 55.7364 220.918V473.854C78.7633 444.828 109.333 414.508 148.115 392.031C250.739 332.554 372 350.526 372 350.526C372 350.526 326.874 464.93 224.25 524.408C165.418 558.505 100.461 567.148 55.7364 568.316V760.273H0.365234C0.365234 760.273 0.438318 322.552 0.438318 247.861C0.438318 173.17 47.8713 102.731 148.115 44.6331C250.739 -14.8445 372 3.12783 372 3.12783Z" fill="#001D45"/>
  </svg>
</template>

<script>
export default {
  name: 'NiniLogo'
};
</script>
