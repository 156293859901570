import { h } from 'vue';
import chatMenuIcon from '@/shared/ui/ChatMenuIcon.vue';

const customSvgNameToComponent = {
  chatMenuIcon,
};

const customSVGs = {
  component: (props) => h(customSvgNameToComponent[props.icon]),
};

export { customSVGs };