import axios from 'axios';
import axiosRetry from 'axios-retry';

export const httpClient = axios.create({
  baseURL: 'https://mppm.devstage.ru/misc/wiki/api/v1',
});

axiosRetry(httpClient, {
  retries: 2,
  retryDelay: () => 30000,
  retryCondition: axiosRetry.isRetryableError,
});

export const setTokenInRequest = () => {
  httpClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['x-authorization'] = token;
    }
    return config;
  });
};
