<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 14H13C13.2833 14 13.521 13.904 13.713 13.712C13.905 13.52 14.0007 13.2827 14 13C14 12.7167 13.904 12.479 13.712 12.287C13.52 12.095 13.2827 11.9993 13 12H7C6.71667 12 6.479 12.096 6.287 12.288C6.095 12.48 5.99933 12.7173 6 13C6 13.2833 6.096 13.521 6.288 13.713C6.48 13.905 6.71733 14.0007 7 14ZM7 11H17C17.2833 11 17.521 10.904 17.713 10.712C17.905 10.52 18.0007 10.2827 18 10C18 9.71667 17.904 9.479 17.712 9.287C17.52 9.095 17.2827 8.99934 17 9H7C6.71667 9 6.479 9.096 6.287 9.288C6.095 9.48 5.99933 9.71734 6 10C6 10.2833 6.096 10.521 6.288 10.713C6.48 10.905 6.71733 11.0007 7 11ZM7 8H17C17.2833 8 17.521 7.904 17.713 7.712C17.905 7.52 18.0007 7.28267 18 7C18 6.71667 17.904 6.479 17.712 6.287C17.52 6.095 17.2827 5.99934 17 6H7C6.71667 6 6.479 6.096 6.287 6.288C6.095 6.48 5.99933 6.71734 6 7C6 7.28334 6.096 7.521 6.288 7.713C6.48 7.905 6.71733 8.00067 7 8ZM6 18L3.7 20.3C3.38334 20.6167 3.02067 20.6877 2.612 20.513C2.20333 20.3383 1.99933 20.0257 2 19.575V4C2 3.45 2.196 2.979 2.588 2.587C2.98 2.195 3.45067 1.99934 4 2H20C20.55 2 21.021 2.196 21.413 2.588C21.805 2.98 22.0007 3.45067 22 4V16C22 16.55 21.804 17.021 21.412 17.413C21.02 17.805 20.5493 18.0007 20 18H6ZM5.15 16H20V4H4V17.125L5.15 16Z"
      fill="rgb(24, 103, 192)"
    />
  </svg>
</template>

<script>
export default {
  name: 'ChatMenuIcon',
};
</script>
