import { httpClient } from '@/app/api/_httpClient';

const url = '/auth/';

const login = async (form) => (
  await httpClient.post(url, form)
);

const authorization = {
  login,
};

export default authorization;
