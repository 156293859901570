import { createRouter, createWebHashHistory } from 'vue-router';
import guards from './guards';

import defaultLayout from '@/layouts/default';

const routes = [
  {
    path: '/auth',
    name: 'authorization',
    component: () => import('@/pages/authorization'),
    beforeEnter: guards.isGuest,
  },
  {
    path: '/',
    component: defaultLayout,
    redirect: '/articles',
    children: [
      { path: 'articles', name: 'articles', component: () => import('@/pages/articles/index') },
      { path: 'articles/:id', name: 'articles-id', component: () => import('@/pages/articles/_id') },
      { path: 'admin', name: 'admin', component: () => import('@/pages/admin') },
      { path: 'adminsingle', name: 'adminsingle', component: () => import('@/pages/adminsingle/index') },
      { path: 'adminsingle/:id', name: 'adminsingle-id', component: () => import('@/pages/adminsingle/_id') },
      { path: 'profile', name: 'profile', component: () => import('@/pages/profile') },
      { path: 'chats', name: 'chats', component: () => import('@/pages/chat.vue') },
    ],
    beforeEnter: guards.isUser,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error',
    component: () => import('@/pages/error'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
