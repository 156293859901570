const parseToken = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
};

const useToken = () => {
  const token = localStorage.getItem('token');
  let decodedToken = null;
  if (token) {
    decodedToken = parseToken(token);
  }
  return decodedToken;
};

export default useToken;
