const useEncoders = () => {
  const toBase64 = (file = {}) =>
    new Promise((resolve, reject) => {
      if (!file) {
        return resolve(null);
      }
      delete file.id;
      delete file.blobPreview;
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    }
  );
  return {
    toBase64,
  };
};

export default useEncoders;
