import { createApp } from 'vue';
import notifications from '@kyvg/vue3-notification';
import App from './App.vue';
import * as api from '@/app/api';
import router from '@/app/router';
import { vuetify, QuillEditor } from '@/app/plugins';
import { Bugfender } from '@bugfender/sdk';

import '@/app/assets/style.scss';

Bugfender.init({
  appKey: 'V07jNibM2XKZ79unh4ZcLTa8hMbnvYD4',
  logBrowserEvents: false,
  logUIEvents: false,
  overrideConsoleMethods: false,
});

createApp(App)
  .use(router)
  .use(notifications)
  .use(vuetify)
  .component('QuillEditor', QuillEditor)
  .provide('api', api)
  .mount('#app');
