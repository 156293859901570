<template>
  <div class="chat-window d-flex row align-end">
    <div v-if="openedChats.length > 0" class="d-flex row">
      <ChatWindow
        v-for="chat in openedChats"
        :key="chat.id"
        :chat="chat"
        @minimize="minimizeChat"
        @close="closeChat"
      ></ChatWindow>
    </div>
    <div class="chat-list rounded-lg" :class="{'chat-list-opened': isOpened == 'opened'}">
      <VToolbar density="compact" class="rounded-t-lg toolbar-styles">
        <div class="ml-2 font-weight-medium line-h20">{{ title }}</div>
        <VSpacer></VSpacer>
        <VBtn size="x-small" @click="minimiseWindow">
          <VIcon icon="open_in_full" size="16"></VIcon>
        </VBtn>
        <VBtn size="x-small" @click="closeWindow">
          <VIcon icon="close" size="16"></VIcon>
        </VBtn>
      </VToolbar>
      <VVirtualScroll
        v-if="isOpened == 'opened'"
        :items="pageInfo?.data"
      >
        <template v-slot:default="{ item }">
          <VListItem
            :key="item.id"
            lines="one"
            height="66"
            @click="openChat(item)"
          >
            <template v-slot:prepend>
              <VAvatar size="x-large">
                <VImg :src="item.avatar"></VImg>
              </VAvatar>
            </template>
            <template v-slot:title>
              <VListItemTitle class="font-weight-medium line-h20">{{ item.title }}</VListItemTitle>
            </template>
            <template v-slot:subtitle>
              <VListItemSubtitle class="text-caption">{{ item.description }}</VListItemSubtitle>
            </template>
          </VListItem>
        </template>
      </VVirtualScroll>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { ChatWindow } from '..';
import { useListFetcher } from '@/shared/model';

const props = defineProps({
  loadingText: {
    type: String,
    default: 'Загрузка чатов...',
  },
  type: {
    type: String,
    default: 'chat',
    validator(value) {
      return ['articles', 'edits', 'single_edits', 'chat', 'admin_chats'].includes(value);
    },
  },
  title: {
    type: String,
    default: 'Мои чаты',
  },
  isOpened: {
   type: String,
   default: 'closed',
  }
});
const emits = defineEmits(['change-window-status']);
const openedChats = ref([]);
const openChat = (item) => {
  const chat = {
    isOpened: true,
    ...item,
  };
  openedChats.value.push(chat);
};
const minimizeChat = (id) => {
  openedChats.value = openedChats.value.map((chat) => ({
    ...chat,
    isOpened: chat.id === id ? !chat.isOpened : chat.isOpened
  }));
};
const closeChat = (id) => {
  openedChats.value = openedChats.value.filter(chat => chat.id !== id);
};
const { pageInfo, loadItems } = useListFetcher(props.type);
loadItems();
const minimiseWindow = () => {
  const status = props.isOpened === 'opened' ? 'minimized' : 'opened';
  emits('change-window-status', status);
};
const closeWindow = () => {
  openedChats.value = [];
  emits('change-window-status', 'closed');
};
</script>

<style lang="scss" scoped>
.chat-window {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;
}
.chat-list {
  width: 360px;
  max-height: 100vh;
  background-color: #fff;
  border: 1px solid #e0e0e0;
}
.chat-list-opened {
  height: 100vh;
}
.toolbar-styles {
  height: 52px;
}
.line-h20 {
  line-height: 20px;
}
</style>
