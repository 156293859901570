const isUser = (to, from, next) => {
  const foundToken = localStorage.getItem('token');
  foundToken ? next() : next({ path: '/auth' });
};

const isGuest = (to, from, next) => {
  const foundToken = localStorage.getItem('token');
  foundToken ? next({ path: '/' }) : next();
};

const guards = {
  isUser,
  isGuest,
};

export default guards;
