import { httpClient, setTokenInRequest } from '@/app/api/_httpClient';

const BASE_URL = '/users/';

const getAll = async () => {
  setTokenInRequest();
  return await httpClient.get(BASE_URL);
};

const users = {
  getAll,
};

export default users;
