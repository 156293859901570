import { httpClient, setTokenInRequest } from '@/app/api/_httpClient';
import { Bugfender } from '@bugfender/sdk';

const BASE_URL = '/actions/';

const getAll = async (params = {}) => {
  setTokenInRequest();
  const options = {
    params: {
      limit: params.itemsPerPage,
      page: parseInt(params.page) - 1,
    },
  };
  return await httpClient.get(BASE_URL, options);
};

const getGroup = async (params = {}, groupId) => {
  setTokenInRequest();
  const options = {
    params: {
      limit: params.itemsPerPage,
      page: parseInt(params.page) - 1,
      groupId
    },
  };
  return await httpClient.get(BASE_URL, options);
};

const resolve = async (currentOperation = {}) => {
  const ACTIONS_FLAGS = {
    'подтвердить': true,
    'отклонить': false,
  };
  const form = {
    id: currentOperation.item.id,
    action: ACTIONS_FLAGS[currentOperation.action],
  };
  const url = `${BASE_URL}resolve/`;
  Bugfender.log('/api/v1/actions/resolve call');
  Bugfender.log(form);
  return await httpClient.post(url, form);
};

const searchInAll = async (params) => {
  setTokenInRequest();
  const options = {
    params: {
      limit: params.itemsPerPage,
      page: parseInt(params.page) - 1,
      ...params.search,
    },
  };
  Bugfender.log('/api/v1/actions call');
  Bugfender.log(options);
  return await httpClient.get(BASE_URL, options);
};

const searchInGroup = async (params = {}, groupId) => {
  setTokenInRequest();
  const options = {
    params: {
      limit: params.itemsPerPage,
      page: parseInt(params.page) - 1,
      groupId,
      ...params.search,
    },
  };
  Bugfender.log('/api/v1/actions call');
  Bugfender.log(options);
  return await httpClient.get(BASE_URL, options);
};

const editGroups = {
  getAll,
  getGroup,
  resolve,
  searchInAll,
  searchInGroup,
};

export default editGroups;