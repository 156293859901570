import { httpClient, setTokenInRequest } from '@/app/api/_httpClient';
import { useEncoders } from '@/shared/lib';
import { Bugfender } from '@bugfender/sdk';

const BASE_URL = '/list/element/update/';

const _performOperation = (action = 'deleteImage') => async(section, image, articleId) => {
  setTokenInRequest();
  const body = [{
    id: section.id,
    fields: [],
    misc: {
      action,
      fieldId: image.field.id,
      id: image.imageId ?? image.content,
      add: image?.comment ?? '',
      license: image?.license ?? null,
      newId: image?.newId ?? null,
    },
  }];
  const url = `${BASE_URL}?id=${articleId}`;
  Bugfender.log(`/api/v1/list/element/update/?id=${articleId} call`);
  Bugfender.log(body);
  return await httpClient.post(url, body);
};

const remove = async (section, image, articleId) => (
  await _performOperation('deleteImage')(section, image, articleId)
);

const restore = async (section, image, articleId) => (
  await _performOperation('restoreImage')(section, image, articleId)
);

const addExternal = async (section, image, articleId) => (
  await _performOperation('addExternalImage')(section, image, articleId)
);

const { toBase64 } = useEncoders();
const addOwn = async (section, image, articleId) => (
  await toBase64(image.content).then(async (encodedImage) => {
    image.content = encodedImage;
    return await _performOperation('addOwnImage')(section, image, articleId);
  })
);

const move = async (section, image, articleId) => (
  await _performOperation('moveImage')(section, image, articleId)
);

const images = {
  remove,
  restore,
  addExternal,
  addOwn,
  move,
};

export default images;
