import { httpClient, setTokenInRequest } from '@/app/api/_httpClient';
import { Bugfender } from '@bugfender/sdk';

const BASE_URL = '/actions/bulk/';

const getAll = async (params = {}) => {
  setTokenInRequest();
  const options = {
    params: {
      limit: params.itemsPerPage,
      page: parseInt(params.page) - 1,
    },
  };
  return await httpClient.get(BASE_URL, options);
};

const resolve = async (currentOperation = {}) => {
  const ACTIONS_FLAGS = {
    'подтвердить': true,
    'отклонить': false,
  };
  const form = {
    id: currentOperation.item.id,
    action: ACTIONS_FLAGS[currentOperation.action],
  };
  const url = `${BASE_URL}resolve/`;
  Bugfender.log('/api/v1/actions/bulk/resolve call');
  Bugfender.log(form);
  return await httpClient.post(url, form);
};

const searchInAll = async (params) => {
  setTokenInRequest();
  const options = {
    params: {
      limit: params.itemsPerPage,
      page: parseInt(params.page) - 1,
      ...params.search,
    },
  };
  Bugfender.log('/api/v1/actions/bulk call');
  Bugfender.log(options);
  return await httpClient.get(BASE_URL, options);
};

const edits = {
  getAll,
  resolve,
  searchInAll,
};

export default edits;
