import { useRouter } from 'vue-router';
import { useNotification } from '@kyvg/vue3-notification';

const useLogout = () => {
  const router = useRouter();
  const { notify } = useNotification();
  const logout = () => {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }
    notify({
      type: 'success',
      text: 'Вы покинули свою учетную запись',
    });
    router.push('/auth');
  };
  return { logout };
};

export default useLogout;
