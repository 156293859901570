const _prepareFieldData = (name, values = {}) => {
  const adaptedField = {
    name,
    oldValue: values.oldValue,
    newValue: values.newValue,
  };
  if (values.enumsWereChanged) {
    adaptedField.addNewEnum = values.enums;
  }
  if (values.type === 'integer') {
    adaptedField.newValue = parseInt(adaptedField.newValue || '0');
  }
  return adaptedField;
};

const adaptBeforeSave = (form) => {
  form = JSON.parse(JSON.stringify(form));
  const adaptedForm = [];
  for (const parentSectionId in form) {
    const result = {
      id: parentSectionId,
      fields: [],
    };
    for (const fieldName in form[parentSectionId]) {
      const fieldValues = form[parentSectionId][fieldName];
      const fieldData = _prepareFieldData(fieldName, fieldValues);
      result.fields.push(fieldData);
    }
    adaptedForm.push(result);
  }
  return adaptedForm;
};

const articlesAdapter = {
  adaptBeforeSave,
};

export default articlesAdapter;
