import { httpClient, setTokenInRequest } from '@/app/api/_httpClient';
import { Bugfender } from '@bugfender/sdk';

const BASE_URL = '/chat/list/';

const getAll = async () => {
  setTokenInRequest();
  return await httpClient.get(BASE_URL);
};

const search = async (params) => {
  setTokenInRequest();
  const options = {
    params
  };
  Bugfender.log('/api/v1/chat/list call');
  Bugfender.log(options);
  return await httpClient.get(BASE_URL, options);
};

const resolve = async (currentOperation = {}, comment = '') => {
  setTokenInRequest();
  const body = [{
    action: currentOperation.id,
    comment: comment ?? '',
  }];
  const url = `${BASE_URL}resolve/?id=${currentOperation.itemId}`;
  Bugfender.log(`/api/v1/chat/list/resolve/?id=${currentOperation.itemId} call`);
  Bugfender.log(body);
  return await httpClient.post(url, body);
};

const admin_chats = {
  getAll,
  resolve,
  search,
};

export default admin_chats;