<template>
  <svg
    width="165"
    height="67"
    viewBox="0 0 165 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1327_2405)">
      <path
        d="M0.321289 2.86598C0.321289 1.28314 1.59218 0 3.15991 0H59.757C61.3248 0 62.5957 1.28314 62.5957 2.86598V20.714C62.5957 24.3618 60.6681 27.7326 57.5392 29.5564C60.6681 31.3804 62.5957 34.751 62.5957 38.3989V64.0391C62.5957 65.6218 61.3248 66.9051 59.757 66.9051H21.6117V52.3268C25.0509 52.2365 30.0462 51.568 34.5705 48.9308C42.4624 44.3304 45.9325 35.4819 45.9325 35.4819C45.9325 35.4819 36.6075 34.0918 28.7156 38.692C25.7333 40.4305 23.3824 42.7757 21.6117 45.0207V25.4574C25.0509 25.367 30.0462 24.6984 34.5705 22.0611C42.4624 17.461 45.9325 8.61236 45.9325 8.61236C45.9325 8.61236 36.6075 7.22229 28.7156 11.8226C21.0069 16.3161 17.3592 21.7644 17.3592 27.5412C17.3592 33.0162 17.3542 63.7109 17.3536 66.9051H3.15991C1.59218 66.9051 0.321289 65.622 0.321289 64.0391V2.86598Z"
        fill="#023C8D"
      />
      <path
        d="M101.983 2.86598C101.983 1.28314 103.254 0 104.822 0H161.419C162.986 0 164.257 1.28314 164.257 2.86598V20.714C164.257 24.3618 162.33 27.7326 159.201 29.5564C162.33 31.3804 164.257 34.751 164.257 38.3989V64.0391C164.257 65.6218 162.986 66.9051 161.419 66.9051H123.273V52.3268C126.713 52.2365 131.708 51.568 136.232 48.9308C144.124 44.3304 147.594 35.4819 147.594 35.4819C147.594 35.4819 138.269 34.0918 130.377 38.692C127.395 40.4305 125.044 42.7757 123.273 45.0207V25.4574C126.713 25.367 131.708 24.6984 136.232 22.0611C144.124 17.461 147.594 8.61236 147.594 8.61236C147.594 8.61236 138.269 7.22229 130.377 11.8226C122.668 16.3161 119.021 21.7644 119.021 27.5412C119.021 33.0162 119.016 63.7109 119.015 66.9051H104.822C103.254 66.9051 101.983 65.622 101.983 64.0391V2.86598Z"
        fill="#023C8D"
      />
      <path
        d="M66.8538 2.86598C66.8538 1.28314 68.1246 0 69.6924 0H94.8862C96.4539 0 97.7248 1.28314 97.7248 2.86598V21.7319C97.7248 28.3853 95.3524 35.1038 89.7821 38.6585C89.3295 38.9474 88.8692 39.2283 88.401 39.5007C87.8026 39.8488 87.1984 40.1789 86.5896 40.4916H94.8862C96.4539 40.4916 97.7248 41.7747 97.7248 43.3576V64.0391C97.7248 65.6218 96.4539 66.9051 94.8862 66.9051H69.6924C68.1246 66.9051 66.8538 65.6218 66.8538 64.0391V31.5603C70.0636 31.6882 76.3609 31.4312 81.9416 28.1847C89.8335 23.5936 93.3037 14.7627 93.3037 14.7627C93.3037 14.7627 83.9787 13.3754 76.0868 17.9665C71.6937 20.5222 68.6707 24.3917 66.8538 27.3203V2.86598Z"
        fill="#023C8D"
      />
    </g>
    <defs>
      <clipPath id="clip0_1327_2405">
        <rect width="164" height="67" fill="white" transform="translate(0.321289)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'BaseLogo'
};
</script>
