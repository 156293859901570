import articlesAdapter from './adapters/articles';
import { httpClient, setTokenInRequest } from '@/app/api/_httpClient';
import { Bugfender } from '@bugfender/sdk';

const BASE_URL = '/list/';

const getAll = async (params = {}) => {
  setTokenInRequest();
  const options = {
    params: {
      limit: params.itemsPerPage,
      page: parseInt(params.page) - 1,
    },
  };
  return await httpClient.get(BASE_URL, options);
};

const get = async (id) => {
  setTokenInRequest();
  const options = {
    params: {
      id,
    },
  };
  const url = `${BASE_URL}element/`;
  return await httpClient.get(url, options);
};

const save = async (form, id) => {
  setTokenInRequest();
  form = articlesAdapter.adaptBeforeSave(form);
  const url = `${BASE_URL}element/update/?id=${id}`;
  Bugfender.log(`/api/v1/list/element/update/?id=${id} call`);
  Bugfender.log(form);
  return await httpClient.post(url, form);
};

const search = async (params = {}) => {
  setTokenInRequest();
  const options = {
    params: {
      limit: params.itemsPerPage,
      page: parseInt(params.page) - 1,
      search: params.search,
    },
  };
  Bugfender.log('/api/v1/list call');
  Bugfender.log(options);
  return await httpClient.get(BASE_URL, options);
};

const articles = {
  getAll,
  get,
  save,
  search,
};

export default articles;
