import { ref } from 'vue';

const useChats = () => {
  const windowStatus = [ 'opened', 'minimized', 'closed' ];
  const chatWindowStatus = ref('closed');
  const channgeChatWindowStatus = (status) => {
    chatWindowStatus.value = status;
  };

  return { chatWindowStatus, channgeChatWindowStatus };
};

export default useChats;
